<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
            <b-col lg="10" offset-lg="1" sm="12">
                <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                          <ValidationProvider name="Select Category" vid="category_id" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Category <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="categoryList"
                                    id="category_id"
                                    rows="6"
                                    v-model="formData.category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        <ValidationProvider name="Name" vid="name" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Title <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="name"
                                rows="6"
                                v-model="formData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Time" vid="time" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Time <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="time"
                                rows="6"
                                v-model="formData.time"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Thumbnail" vid="thumbnail" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Thumbnail Image<span class="text-danger">*</span>
                            </template>
                            <b-form-file
                                id="thumbnail"
                                rows="6"
                                @change="handleImg"
                                v-model="formData.thumbnail"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                            </div>
                        </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        title: '',
        thumbnail: null,
      }
    }
  },
  computed: {
      categoryList () {
        return this.$store.state.commonObj.quizCategoryList
      },
      loading () {
        return this.$store.state.static.loading
      },
      vpnList () {
        return this.$store.state.commonObj.vpnList
      }
  },
  methods: {
    handleImg( event ){
        this.formData.thumbnail = event.target.files[0];
    },
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify({...item}))
    },
    async register () {
          this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
          let result = null
          let formData = new FormData()

            Object.keys(this.formData).map(key => {
                
                formData.append(key, this.formData[key])
                
            })

        if (this.id) {
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/quiz-sub-categories/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/quiz-sub-categories/store', formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
